#left-menu-toggle .peicon {
    color: #04A8F4;
}

#right-menu-toggle .peicon {
    color: #04A8F4;
}

.pt-page-1 h2 {
    color: #04A8F4;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
    background: #04A8F4 !important;
}

.main-nav .peicon {
    color: #04A8F4;
}

.main-nav .peicon:hover {
    background: #04A8F4;
}

.main-nav li .active>i {
    background: #04A8F4;
}

.main-nav a.back-track span {
    color: #04A8F4;
}

.timeline>li>.timeline-item>.timeline-header {
    background: #04A8F4;
}

.resume-container .res-sec-date {
    color: #04A8F4;
}

.av-freelance,
.personalinfo-item i {
    background: #04A8F4;
}

.skills-cardcontent .skill-percent {
    background: #04A8F4;
}

.skills-cardfooter .skills-label {
    background: #04A8F4;
}

.bar-percentage {
    background: #04A8F4;
}

.skills-container-3 .bar,
.skills-container-2 .bar {
    background: #04A8F4;
}

.labelsection {
    background: #04A8F4;
}

figure.snip1204 .author h5 {
    color: #04A8F4;
}

.service-iconbox-2 {
    background: #04A8F4;
}

.service-iconbox h5,
.service-iconbox-3 h5 {
    color: #04A8F4;
}

.image-service .image-service-content span {
    background: #04A8F4;
}

.counter-section .peicon {
    color: #04A8F4;
}

.portfolio-items .portfolio-cardcontent .magnifictrig {
    background: #04A8F4;
}

.portfolio-items .portfolio-cardcontent .magnifictrig:hover {
    color: #04A8F4;
}

.portfocount_container {
    background: #04A8F4;
}

.modal-header .modal-title {
    color: #04A8F4;
}

.single-portfolio-detail .single-portfolio-label span {
    background: #04A8F4;
}

.blog-card .cardtag .label {
    background: #04A8F4;
}

.post-heading .posts-tag .label {
    background: #04A8F4;
}

.post-heading .subheading {
    color: #04A8F4;
}

.p1 a.is-active {
    background-color: #04A8F4;
}

#right-sidebar-wrapper .sidebarwidgets .bio-details .bio-more {
    background: #04A8F4;
}

.recent-activity ul li:before {
    border: 2px solid #04A8F4;
}

.cta-wrapper {
    background-color: #04A8F4;
}

.contactinfo .peicon {
    background: #04A8F4;
}

#plan h2 {
    color: #04A8F4;
}

#plan .price-table .table-inner .phrase {
    color: #04A8F4;
}

#plan .price-table .table-inner .btn-blue {
    border: 1px solid #04A8F4;
    color: #04A8F4;
}

.calendar-title {
    color: #04A8F4;
}

.headercal h1 {
    color: #04A8F4;
}

.leftcal,
.rightcal {
    color: #04A8F4;
}

.day.today .day-number {
    background: #04A8F4 !important;
}

.day.today .day-name {
    color: #04A8F4;
}

#nprogress .bar {
    background: #04A8F4;
}

#nprogress .peg {
    box-shadow: 0 0 10px #04A8F4, 0 0 5px #04A8F4;
}

#nprogress .spinner-icon {
    border-top-color: #04A8F4;
    border-left-color: #04A8F4;
}

.clickedday {
    color: #04A8F4;
}